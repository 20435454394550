import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, SecondaryCallout, KMCurve, ComparisonTable, EndpointDisplay } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import MSI_CRC from '../../components/StudyDesign/__study-design/msi-crc'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-177 - Clinical Trial Results | HCP`,
    keywords: `keynote 177, msi-h colorectal cancer clinical trial results`,
    description: `Health care professionals may find clinical trial results for KEYNOTE-177 in patients with advanced MSI-H/dMMR colorectal cancer (CRC).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/msi-h-dmmr-colorectal-cancer/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠177","description":"Clinical Findings from KEYNOTE⁠-⁠177","trialDesign":"The efficacy of KEYTRUDA was investigated in KEYNOTE⁠-⁠177, a multicenter, randomized, open-label, active-controlled trial that enrolled 307 patients with previously untreated unresectable or metastatic MSI⁠-⁠H or dMMR CRC."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/msi-h-dmmr-colorectal-cancer/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 23;
const jobCode = jobCodes[23].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'MSI&#8288;-&#8288;H/dMMR Cancers', url: '/efficacy/msi-h-dmmr/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/msi-h-dmmr-colorectal-cancer/' },
                { text: 'Dosing', url: '/dosing/options/' },
                { text: 'MSI/MMR Testing', url: '/biomarker-testing/msi-mmr/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;177' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;177' }
]

const key177SecondaryCalloutFootnotes = [
    {
        label: "a.",
        text: "mFOLFOX6 (oxaliplatin, leucovorin, and [fluorouracil] FU) or mFOLFOX6 in combination with either bevacizumab or cetuximab or FOLFIRI (irinotecan, leucovorin, and FU) or FOLFIRI in combination with either bevacizumab or cetuximab."
    },
    {
        label: "b.",
        text: "Based on Cox regression model."
    },
    {
        label: "c.",
        text: "Two-sided <i>P</i> value based on log-rank test (compared to a significance level of 0.0234)."
    },
];

const key177KMcurveFootnotes = [
    {
        label: "d.",
        text: "As assessed by BICR according to RECIST v1.1, modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ."
    },
    {
        label: "e.",
        text: "Based on Cox regression model."
    },
    {
        label: "f.",
        text: "Two-sided <i>P</i> value based on log-rank test (compared to a significance level of 0.0234)."
    },
];

const PFSComparisonFootnotes = [
    {
        label: 'g.',
        text: 'Based on Cox regression model.'
    },
    {
        label: 'h.',
        text: 'Two-sided <i>P</i> value based on log-rank test (compared to a significance level of 0.0234).'
    },
]

const OSrateFootnotes = [
    {
        label: 'i.',
        text: 'Final OS analysis.'
    },
    {
        label: 'j.',
        text: 'Based on Cox regression model.'
    },
    {
        label: 'k.',
        text: 'Two-sided <i>P</i>-value based on log-rank test (compared to a significance level of 0.0492).'
    },
]

const OREndpointFootnotes = [
    {
        label: 'l.',
        text: 'Based on confirmed response by BICR review.'
    },
]

const DORtableFootnotes = [
    {
        label: 'm.',
        text: 'Based on confirmed response by BICR review.'
    },
    {
        label: 'n.',
        text: 'Based on n=67 patients with a response in the KEYTRUDA arm and n=51 patients with a response in the chemotherapy arm.'
    },
    {
        label: 'o.',
        text: 'Based on observed DOR.'
    },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">The First Anti–PD&#8288;-&#8288;1 Approved for First-line Treatment of Advanced MSI&#8288;-&#8288;H/dMMR CRC</PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <SecondaryCallout 
                                title="40% reduction in the risk of disease progression or death with KEYTRUDA vs chemotherapy<sup>a</sup>" 
                                text="HR<sup>b</sup>=0.60; 95% CI, 0.45–0.80; <i>P</i><sup>c</sup>=0.0004" 
                                alt="40% Reduction in the Risk of Disease Progression or Death With KEYTRUDA® (pembrolizumab) vs Chemotherapy"
                                footnotes={key177SecondaryCalloutFootnotes}
                            />
                            <KMCurve
                                title="Kaplan-Meier Estimates of PFS With KEYTRUDA vs Chemotherapy<sup>d</sup>"
                                hr="HR<sup>e</sup>=0.60; 95% CI, 0.45–0.80; <i>P</i><sup>f</sup>=0.0004"
                                footnotes={key177KMcurveFootnotes}
                                definitions="BICR = blinded independent central review; CI = confidence interval; HR = hazard ratio; PD&#8288;-&#8288;1 = programmed death receptor-1; PFS = progression-free survival; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1."
                                image={() => <StaticImage src="../../assets/crc-177-km-v-2.png"
                                    placeholder="blurred"
                                    alt="Kaplan-Meier Estimates of PFS in Patients With Advanced MSI-H/dMMR Colorectal Cancer (CRC) With KEYTRUDA® (pembrolizumab) vs Chemotherapy In KEYNOTE-177"
                                />} />

                            <ComparisonTable 
                                title="Progression-Free Survival (PFS): Double the median PFS with KEYTRUDA" 
                                footnotes={PFSComparisonFootnotes}
                                data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA (n=153)',
                                            },
                                            {
                                                legendTitle: 'Chemotherapy (n=154)',
                                            },
                                        ],
                                        [
                                            {
                                                hrCiP: 'HR<sup>g</sup>=0.60; 95% CI, 0.45–0.80; <i>P</i><sup>h</sup>=0.0004',
                                                colspan: 2,
                                                centerText: true,
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Events observed',
                                                dataPoint: '54%',
                                                dataSubPoint: '(n=82/153)'
                                            },
                                            {
                                                label: 'Events observed',
                                                dataPoint: '73%',
                                                dataSubPoint: '(n=113/154)'
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '16.5 months',
                                                dataSubPoint: '(95% CI, 5.4–32.4)'
                                            },
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '8.2 months',
                                                dataSubPoint: '(95% CI, 6.1–10.2)'
                                            },
                                        ]
                                
                                    ]
                                }
                            />

                            {/* OS Data */}
                            <ComparisonTable 
                                title="Overall Survival (OS)<sup>i</sup> Rate" 
                                limitation="There was no statistically significant difference between KEYTRUDA and chemotherapy in the final OS analysis. Sixty percent of the patients who had been randomized to receive chemotherapy had crossed over to receive subsequent anti–PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 therapies including KEYTRUDA. Median follow-up time at the final analysis was 38.1 months (range: 0.2 to 58.7 months)."
                                footnotes={OSrateFootnotes} 
                                data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA (n=153)',
                                            },
                                            {
                                                legendTitle: 'Chemotherapy (n=154)',
                                            },

                                        ],
                                        [
                                            {
                                                colspan: 2,
                                                centerText: true,
                                                hrCiP: 'HR<sup>j</sup>=0.74; 95% CI, 0.53–1.03; <i>P</i><sup>k</sup>=0.0718'
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Median OS',
                                                dataPoint: 'Not reached (95% CI 49.2, Not reached)',
                                            },
                                            {
                                                label: 'Median OS',
                                                dataPoint: '36.7 months (95% CI 27.6, Not reached)',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '41%',
                                                dataSubPoint: '(n=62)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '51%',
                                                dataSubPoint: '(n=78)'
                                            },
                                        ],
                                    ]
                                } 
                            />

                            <PageSubSection title="Objective Response Rate and Duration of Response In First-Line Treatment of Advanced MSI&#8288;-&#8288;H/dMMR CRC">
                                <EndpointDisplay 
                                    title="Objective Response Rate (ORR)<sup>l</sup>" 
                                    footnotes={OREndpointFootnotes}
                                    cards={
                                        [
                                            {
                                                dataType: 'ORR',
                                                label: 'KEYTRUDA',
                                                rate: '44%',
                                                n: '67',
                                                ci: '95% CI, 35.8–52.0',
                                                completeResponseRate: '11%',
                                                partialResponseRate: '33%',
                                            },
                                            {
                                                dataType: 'ORR',
                                                label: 'Chemotherapy',
                                                rate: '33%',
                                                n: '51',
                                                ci: '95% CI, 25.8–41.1',
                                                completeResponseRate: '4%',
                                                partialResponseRate: '29%',
                                            }
                                        ]
                                    }
                                />

                                <ComparisonTable 
                                    title="Duration of Response (DOR)<sup>m,n</sup>" 
                                    limitation="DOR among 67 responding patients treated with KEYTRUDA and 51 responding patients treated with chemotherapy" 
                                    definitions="<p>+ Denotes ongoing response.</p><p>CPS = combined positive score; PD&#8288;-&#8288;L1 = programmed death ligand 1.</p>" 
                                    footnotes={DORtableFootnotes} 
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=67)',
                                                },
                                                {
                                                    legendTitle: 'Chemotherapy (n=51)',
                                                },

                                            ],
                                            [
                                                {
                                                    label: 'Median DOR',
                                                    dataPoint: 'Not reached',
                                                    dataSubPoint: '(range: 2.3+ to 41.4+ months)'
                                                },
                                                {
                                                    label: 'Median DOR',
                                                    dataPoint: '10.6 months',
                                                    dataSubPoint: '(range: 2.8 to 37.5+ months)'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Patient responses lasting ≥12 months<sup>o</sup>',
                                                    dataPoint: '75%'
                                                },
                                                {
                                                    label: 'Patient responses lasting ≥12 months<sup>o</sup>',
                                                    dataPoint: '37%'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Patient responses lasting ≥24 months<sup>o</sup>',
                                                    dataPoint: '43%'
                                                },
                                                {
                                                    label: 'Patient responses lasting ≥24 months<sup>o</sup>',
                                                    dataPoint: '18%'
                                                },
                                            ],

                                        ]
                                    } 
                                />
                            </PageSubSection>

                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <MSI_CRC />
                            </PageSubSection>
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
